/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, bool, func, string,
} from 'prop-types';
import {
    Card,
    FormControl,
    Grid,
    Button,
    Select,
    MenuItem,
    TextField,
    Typography,
    InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {  useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Helmet } from 'react-helmet';
import GoogleAddressSearch from '../../../../../GraphqlCommonComponents/GoogleAddressSearch';
import LinkOrATag from '../../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import { getFeatureFlag } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTest, getActiveABTests } from '../../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import GnavSkeleton from '../../../../../GraphqlSkeletonComponents/GnavSkeleton';
import QasSuggestionList from '../QasSuggestionList';

const useStyles = makeStyles((theme) => ({
    formControlRoot: {
        width: '100%',
        // removes default mui margin-top
        '& label + $inputRoot': {
            marginTop: 0,
        },
    },
    // do no remove used as a selector
    inputRoot: {
        // zip or address input root (div)
    },
    outerCard: ({ styles }) => ({
        backgroundColor: styles.backgroundColor || '#734f96',
        padding: '13px 13px 16px 13px',
        [theme.breakpoints.up(769)]: {
            margin: 0,
            padding: '20px',
        },
        overflow: 'visible',
    }),
    cardHeaderText: ({ styles }) => ({
        color: styles.formColor || '#ffffff',
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '13px',
        marginTop: '0px',
        [theme.breakpoints.up(769)]: {
            marginBottom: '15px',
            textTransform: 'uppercase',
            fontSize: '14px',
            wrap: 'nowrap',
            marginTop: '0px',
        },
    }),
    submitButton: ({ styles }) => ({
        fontSize: '16px',
        fontWeight: '700',
        textDecoration: 'none',
        textTransform: 'uppercase',
        width: '100%',
        padding: '10px 9px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up(768)]: {
            width: '100%',
            padding: '9px',
        },
        color: styles.submitButtonCopyColor || '#ffffff',
        backgroundColor: styles.submitButtonColor || '#00C876',
        '&:focus-visible': {
            /* Visible in the full-colour space */
            boxShadow: '0 0 0 2px rgba(0,0,0,0.23);',
            /* Visible in Windows high-contrast themes */
            outlineColor: 'transparent',
            outlineWidth: '1px',
            outlineStyle: 'solid',
        },
    }),
    formControl: {
        // removes default mui margin-top
        '& label + div': {
            marginTop: 0,
        },
        width: '100%',
        marginRight: '10px',
        [theme.breakpoints.up(600)]: {
            marginRight: 0,
            marginBottom: '17px',
            width: '100%',
        },
    },
    addressFieldLabel: ({ styles }) => ({
        textTransform: 'uppercase',
        fontWeight: 'bold',
        position: 'relative',
        fontSize: '11px',
        marginBottom: '3px',
        [theme.breakpoints.up(600)]: {
            fontSize: '10px',
        },

        color: styles.addressFieldColor || '#ffffff',
    }),
    addressFieldLabelZipValid: {
        '&::after': {
            position: 'absolute',
            right: 'auto',
            marginLeft: '3px',
            bottom: '1px',
            zIndex: '99',
            backgroundColor: '#00c876',
            color: 'white',
            textAlign: 'center',
            content: '"✓"',
            fontSize: '9px',
            fontWeight: 'bold',
            lineHeight: '17px',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
        },
    },
    textField: {
        backgroundColor: '#FFFFFF',
        padding: '9px 14px 8px 14px',
        border: '1px solid rgba(0,0,0,0.23)',
        borderRadius: '4px',
        boxShadow: '0',
        transition: 'box-shadow 0.15s ease-in-out',
        '&:focus-visible': {
            /* Visible in the full-colour space */
            boxShadow: '0 0 0 2px rgba(0,0,0,0.23);',
            /* Visible in Windows high-contrast themes */
            outlineColor: 'transparent',
            outlineWidth: '1px',
            outlineStyle: 'solid',

        },
        height: '1.58em',
    },
    selectField: {
        backgroundColor: '#FFFFFF',
        height: '1.58em',
        lineHeight: '1.58em',
        padding: '9px 14px 8px 14px',
        border: '1px solid rgba(0,0,0,0.23)',
        borderRadius: '4px',
        '&:focus': { // override MUI focus
            borderRadius: '4px',
            backgroundColor: 'rgb(244, 241, 247)',
        },
        '&:focus-visible': {
            /* Visible in the full-colour space */
            boxShadow: '0 0 0 2px rgba(0,0,0,0.23);',
            /* Visible in Windows high-contrast themes */
            outlineColor: 'transparent',
            outlineWidth: '1px',
            outlineStyle: 'solid',
        },
    },
    icon: {
        top: '50%',
        transform: 'translateY(-50%)',
        right: '5px',
        width: '32px',
        height: '32px',
    },
    locationTypeLabel: ({ styles }) => ({
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '11px',
        marginBottom: '3px',
        [theme.breakpoints.up(600)]: {
            fontSize: '10px',
        },

        color: styles.locationTypeColor || '#ffffff',
    }),
    locationTypeAndButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.up(600)]: {
            flexDirection: 'column',
        },
    },
    submittedZip: {
        fontSize: '20px',
        verticalAlign: 'middle',
    },
    addressField: {
        position: 'relative',
        '&::before': {
            content: '"Invalid zip code"',
            position: 'absolute',
            bottom: '-20px',
            width: '100%',
            height: '22px',
            left: 0,
            paddingLeft: '14px',
            backgroundColor: '#f71449',
            zIndex: '99',
            color: 'white',
            fontSize: '11px',
            fontFamily: 'sans-serif',
            lineHeight: '18px',
            fontWeight: '300',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },
    selectAndGo: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        [theme.breakpoints.up(768)]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    displayNone: {
        display: 'none',
    },

    bottomLinkWrapper: ({ styles }) => ({
        margin: '10px auto 0px',
        color: styles.bottomLinkColor || '#000000',
    }),

    zipCodeContainer: {
        width: '100%',
    },

    locationContainer: {
        marginTop: '13px',
    },

    newGnavDialogStyle: {
        [theme.breakpoints.down(600)]: {
            '& .pac-container': {
                borderTop: '0px',
            },
            '&$outerCard': {
                padding: '20px',
                boxShadow: 'none',
                borderRadius: '3px',
            },
            '& $locationTypeLabel': {
                marginBottom: '6px',
                padding: ' 0px !important',
            },
        },
        '& .MuiGrid-container ': {
            [theme.breakpoints.down(600)]: {
                flexDirection: 'row',
            },
        },
        '& .pac-container': {
            borderTop: '0px',
        },

        '& $cardHeaderText': {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '1.125rem',
            lineHeight: '22px',
            textTransform: 'none',
            marginBottom: '12px',
            [theme.breakpoints.down(600)]: {
                width: '100%',
                fontSize: '1.25rem',
                lineHeight: '24px',
            },
        },
        '& $zipCodeContainer': {
            [theme.breakpoints.down(600)]: {
                display: 'flex',
                flexDirection: 'column',
                width: 'calc((100% - 59%) - 4px)',
                marginRight: '4px',
            },
        },
        '& $locationContainer': {
            [theme.breakpoints.down(600)]: {
                width: '59%',
            },
        },
        '& $addressFieldLabel': {
            marginBottom: '6px',
        },
        '& $textField': {
            marginBottom: '8px',
            [theme.breakpoints.down(600)]: {
                marginBottom: '0px',
                '&:focus': {
                    fontSize: '16px',
                    touchAction: 'manipulation',
                },
            },
        },
        '& $locationTypeLabel , $addressFieldLabel': {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '14px',
            textTransform: 'none',
        },
        '& $locationTypeAndButtonContainer': {
            display: 'flex',
            [theme.breakpoints.up(600)]: {
                flexDirection: 'row',
            },
        },
        '& $submitButton': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 20px',
            minHeight: '36px',
            borderRadius: '3px',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '0.875rem',
            lineHeight: '20px',
            letterSpacing: '0.04em',
            width: '59px',
            minWidth: '59px',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: 'rgb(110, 50, 144)',
            },
            '& .MuiTouchRipple-child': {
                background: 'transparent',
            },
        },
        '& $selectField, $textField': {
            height: 'auto',
            border: '1px solid #E1C3E5',
            borderRadius: '3px',
            padding: '9px 14px 8px 15px',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '0.875rem',
            lineHeight: '17px',
            '&:focus': { // override MUI focus
                borderRadius: '3px',
                backgroundColor: '#fff',
            },
            '&:focus-visible': {
            /* Visible in the full-colour space */
                boxShadow: 'none',
                border: '1px solid #262626',
                /* Visible in Windows high-contrast themes */
                outlineColor: 'unset',
                outlineWidth: '0',
                outlineStyle: 'none',
            },
            [theme.breakpoints.down(600)]: {
                maxHeight: '17px',
            },
        },
        '& $formControl': {
            margin: '0px 4px 0px 0px',
        },
        '& $icon': {
            fill: theme.palette.common?.black || '#000000',
            top: '2px',
        },
        '& $addressField': {
            position: 'relative',
            '&::before': {
                bottom: '-12px',
            },
        },
    },
}));

const locations = ['Residence', 'Business', 'Funeral home', 'Hospital', 'Apartment', 'School', 'Church'];

const GuidedNavUncollapsedView = React.forwardRef(
    (
        {
            guidedNavFormData,
            addressFieldOnFocus,
            addressFieldOnBlur,
            handleAddressChange,
            handleLocationFormChange,
            handleButtonSubmit,
            getAddressInformation,
            loadGoogleMaps,
            addressFieldFocus,
            addressFieldClicked,
            addressFieldOnClick,
            zipValid,
            selectedLocationType,
            currentAddress,
            zipDerivedFromGoogleMaps,
            onSubmitCheck,
            guidedNavModalIsOpen,
            qasSuggestions,
            showQasModel,
            hideQasModel,
            handleKeyDown,
        },
        innerRef,
    ) => {
        const activeOptimizeTests = useSelector(getActiveABTests);
        const abNewGuidedNavFacelift =  useSelector(getActiveABTest('new_guided_nav_facelift')) === 'variant';
        const isUpdatedGnavPresentationFlag = useSelector(getFeatureFlag('is-updated-gnav-presentation'));
        const isUpdatedGnav = isUpdatedGnavPresentationFlag && abNewGuidedNavFacelift;
        const isMobile = useMediaQuery('(max-width: 600px)');

        // the following conditional logic is to account for changes in the address field label text color
        // which changes to brand color on field focus except when the category is locked.
        // if the category is locked and a user clicks a product the focus changes to the address field and
        // the label color becomes red. If they click into the field the color changes to brand color.
        // when not focused the color is value from cms

        let addressFieldLabelColorHighlight = null;
        if (addressFieldClicked && addressFieldFocus && !isUpdatedGnav) {
            addressFieldLabelColorHighlight = guidedNavFormData?.submit_button_background_color || '#00c876';
        }

        const styles = {
            backgroundColor: guidedNavFormData?.background_color,
            formColor: guidedNavFormData?.form_color,
            addressFieldColor: addressFieldLabelColorHighlight || guidedNavFormData?.address_field_color,
            locationTypeColor: guidedNavFormData?.location_type_color,
            submitButtonColor: guidedNavFormData?.submit_button_color,
            submitButtonCopyColor: guidedNavFormData?.submit_button_copy_color,
            bottomLinkColor: guidedNavFormData?.bottom_link_color,
        };

        const classes = useStyles({ styles });

        if (isUpdatedGnavPresentationFlag && (!activeOptimizeTests?.loaded && !activeOptimizeTests?.timeout)) { return <GnavSkeleton />; }

        if (guidedNavFormData) {
            return (
                <Card className={`${classes.outerCard} ${isUpdatedGnav  ? classes.newGnavDialogStyle : ''}`}>
                    {isMobile && isUpdatedGnav && (
                        <Helmet>
                            <style type="text/css">{`
                                   .pac-container{
                                      min-width: calc(100% - 132px) !important;
                                   }
                                `}
                            </style>
                        </Helmet>
                    ) }
                    <Grid container direction="column">
                        <h4
                            className={classes.cardHeaderText}
                        >
                            {guidedNavFormData?.form_label || 'Find Available Products'}
                        </h4>
                        <div className={classes.zipCodeContainer}>
                            <Typography
                                className={
                                // if the guided  nav modal is open we don't want to display any visual data on the normal nav
                                    zipValid === true && !guidedNavModalIsOpen && (currentAddress.length >= 5 || zipDerivedFromGoogleMaps !== '')
                                        ? `${classes.addressFieldLabel} ${classes.addressFieldLabelZipValid}`
                                        : classes.addressFieldLabel
                                }
                            >
                                {guidedNavFormData?.address_field_label || 'Enter Delivery Zip Code Or Address*'}
                            </Typography>
                            <TextField
                                // DO NOT CHANGE ID NAME
                                id="data-test-guidedNavZipOrAddressField"
                                required
                                inputRef={innerRef}
                                onFocus={addressFieldOnFocus}
                                onBlur={addressFieldOnBlur}
                                onKeyDown={handleKeyDown}
                                onClick={addressFieldOnClick}
                                label="data-test-guidedNavZipOrAddressField"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.displayNone,
                                    },
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        root: classes.inputRoot,
                                        input: classes.textField,
                                    },
                                    inputProps: {
                                        'data-element': 'guided_nav_field',
                                    },

                                }}
                                onChange={handleAddressChange}
                                className={
                                    (zipValid === false && currentAddress.length === 5 && !guidedNavModalIsOpen) || onSubmitCheck === true
                                        ? classes.addressField
                                        : null
                                }
                                classes={{ root: classes.formControlRoot }}
                                // if the guided  nav modal is open we don't want to display any visual data on the normal nav
                                value={guidedNavModalIsOpen ? '' : currentAddress}
                            />
                            { showQasModel && qasSuggestions && currentAddress?.length > 0 && currentAddress && <QasSuggestionList handleClickAway={hideQasModel} showModel={showQasModel} handlePickAnAddress={getAddressInformation} suggestions={qasSuggestions} />}
                        </div>
                        <div className={classes.locationContainer}>
                            <Typography
                                className={classes.locationTypeLabel}
                                style={{
                                    paddingTop: (zipValid === false) || (onSubmitCheck === true) ? '15px' : '',
                                }}
                            >
                                {guidedNavFormData?.location_type_label || 'Location Type:'}
                            </Typography>
                            <div className={classes.locationTypeAndButtonContainer}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                        className={classes.displayNone}
                                        htmlFor="data-test-guidedNavLocation"
                                    >
                                        Location
                                    </InputLabel>
                                    <Select
                                    // data-testid="gnav-uncollapsed-location-dropdown"
                                        label="Location"
                                        disableUnderline
                                        inputProps={{
                                            label: 'data-test-guidedNavLocation',
                                            id: 'data-test-guidedNavLocation',
                                            'data-testid': 'gnav-uncollapsed-location-dropdown',
                                            classes: {
                                                root: classes.selectField,
                                                icon: classes.icon,

                                            },
                                        }}
                                        value={selectedLocationType}
                                        onChange={handleLocationFormChange}
                                    >
                                        {locations.map((location) => (
                                            <MenuItem key={location} id={`data-test-guidedNavLocation${location}`} value={location}>{location}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    className={classes.submitButton}
                                    onClick={() => handleButtonSubmit(false)}
                                    // DO NOT CHANGE ID NAME
                                    id="data-test-guidedNavSubmitButton"
                                >
                                    {guidedNavFormData?.submit_button_label || 'Go'}
                                </Button>
                            </div>
                        </div>
                        {(guidedNavFormData?.bottom_link_href && guidedNavFormData?.bottom_link_label) && (
                            <div className={classes.bottomLinkWrapper}>
                                <LinkOrATag
                                    href={guidedNavFormData?.bottom_link_href || '/international-flower-delivery'}
                                >
                                    {guidedNavFormData?.bottom_link_label}
                                </LinkOrATag>
                            </div>
                        )}
                    </Grid>
                    {/*
                    The google component is loaded on focus of the address bar
                    to prevent the scenario where the component loads
                    before the google maps page scripts load.
                    If that happens the component does not have access to window.google
                    and will not work.
                */}
                    {loadGoogleMaps ? (
                        <GoogleAddressSearch
                        // DO NOT CHANGE ID NAME
                            searchID="data-test-guidedNavZipOrAddressField"
                            getAddressInformation={getAddressInformation}
                        />
                    ) : null}
                </Card>
            );
        }
        return null;
    },
);

GuidedNavUncollapsedView.propTypes = {
    guidedNavFormData: object.isRequired,
    addressFieldOnFocus: func.isRequired,
    addressFieldOnBlur: func.isRequired,
    handleAddressChange: func.isRequired,
    handleLocationFormChange: func.isRequired,
    handleButtonSubmit: func.isRequired,
    getAddressInformation: func.isRequired,
    loadGoogleMaps: bool.isRequired,
    selectedLocationType: string.isRequired,
    addressFieldFocus: bool.isRequired,
    addressFieldClicked: bool.isRequired,
    addressFieldOnClick: func.isRequired,
    zipValid: bool,
    currentAddress: string.isRequired,
    zipDerivedFromGoogleMaps: string.isRequired,
    onSubmitCheck: bool.isRequired,
    guidedNavModalIsOpen: bool.isRequired,
    qasSuggestions: object,
    showQasModel: bool,
    hideQasModel: func,
    handleKeyDown: func.isRequired,
};

GuidedNavUncollapsedView.defaultProps = {
    zipValid: null,
    qasSuggestions: {},
    showQasModel: false,
    hideQasModel: () => {},
};

export default GuidedNavUncollapsedView;
