/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
    bool,
    string,
    func,
    object,
} from 'prop-types';
import { setGuidedNavModalHasBeenOpenedInSession } from '../../../../../../../../state/ducks/App/App-Actions';
import GoogleAddressSearch from '../../../../../GraphqlCommonComponents/GoogleAddressSearch';
import LinkOrATag from '../../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import { getFeatureFlag } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTest } from '../../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import QasSuggestionList from '../QasSuggestionList';

const locations = ['Residence', 'Business', 'Funeral home', 'Hospital', 'Apartment', 'School', 'Church'];

const useStyles = makeStyles((theme) => ({
    headerText: {
        fontWeight: 'bold',
        fontSize: '20px',
    },
    dialogTitle: ({ styles }) => ({
        color: styles.formColor || '#5f3c86',
        backgroundColor: styles.formBackgroundColor || '#E5E5E5',
        position: 'relative',
    }),
    closeIconWrapper: ({ styles }) => ({
        color: styles.formColor || '#5f3c86',
        position: 'absolute',
        right: '1%',
        top: '5%',
        cursor: 'pointer',
    }),

    dialogContent: ({ styles }) => ({
        backgroundColor: styles.backgroundColor || '#ffffff',
        padding: '7px 24px',
    }),
    inputField: {
        width: '100%',
    },
    inputFieldInvalidZip: {
        position: 'relative',
        '&::before': {
            content: '"Invalid address or zipcode"',
            position: 'absolute',
            bottom: '-6px',
            width: '100%',
            height: '25px',
            left: 0,
            paddingLeft: '14px',
            backgroundColor: theme.palette?.error?.guidedNav,
            zIndex: '999',
            color: 'white',
            fontSize: '11px',
            fontFamily: 'sans-serif',
            lineHeight: '25px',
            fontWeight: '300',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },

    },
    addressFieldText: ({ styles }) => ({
        color: styles.addressFieldColor || '#5f3c86',
        fontWeight: 'bold',
        position: 'relative',
    }),
    locationFieldText: ({ styles }) => ({
        fontWeight: 'bold',
        color: styles.locationTypeColor || '#5f3c86',
    }),
    inputFieldContainer: {
        marginBottom: '15px',
        marginTop: '5px',
    },
    formControl: {
        width: '100%',
    },
    locationSelect: {
        marginTop: '5px',
    },
    submitButton: ({ styles }) => ({
        textTransform: 'uppercase',
        color: styles.submitButtonCopyColor || '#ffffff',
        backgroundColor: styles.submitButtonColor || '#5f3c86',
        '&:hover': {
            backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    }),
    bottomContainer: {
        marginTop: '15px',
        alignItems: 'center',
    },
    internationalLinkContainer: {
        marginTop: '16px',
    },
    linkWrapper: ({ styles }) => ({
        color: styles.bottomLinkColor || '#000000',
    }),
    headerTextFieldWrapper: {
        marginRight: '10px',
    },
    outerDialog: {
        width: '100%',
    },
    paper: {
        width: '100%',
        minHeight: '372px',
        maxHeight: '372px',
    },
    addressRequiredText: ({ styles }) => ({
        color: styles.addressFieldColor || '#5f3c86',
        fontSize: '16px',
        paddingLeft: '3px',
        paddingTop: '2.5px',
        position: 'relative',
    }),
    addressFieldLabelZipValid: {
        '&::after': {
            position: 'absolute',
            right: 'auto',
            marginLeft: '3px',
            bottom: '1px',
            zIndex: '99',
            backgroundColor: '#00c876',
            color: 'white',
            textAlign: 'center',
            content: '"✓"',
            fontSize: '9px',
            fontWeight: 'bold',
            lineHeight: '17px',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            top: '8px',
        },
    },
    buttonContainer: {},
    newGnavDialogStyle: {
        '& .pac-container': {
            borderTop: '0px',
        },
        '& $paper': {
            maxWidth: '366px',
            minHeight: '306px',
            maxHeight: '306px',
        },
        '& $dialogTitle': {
            padding: '28px 0px 16px',
        },
        '& $headerText': {
            fontFamily: "'Playfair Display'",
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '1.5rem',
            lineHeight: '32px',
            textAlign: 'center',
        },
        '& $dialogContent': {
            padding: '0px 28px',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E1C3E5',
                borderWidth: '1px',
                borderRadius: '3px',
            },
            '& .select-input': {
                borderColor: '#E1C3E5',
                borderWidth: '1px',
                borderRadius: '3px',
            },
            '& .MuiOutlinedInput-input': {
                padding: '8px 15px',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '0.875rem',
                lineHeight: '17px',
                background: theme.palette.common?.white || '#fff',
            },
            '& .MuiSelect-iconOutlined': {
                fill: theme.palette.common?.black || '#000',
                top: 'calc(50% - 13px)',
            },
            '& .MuiOutlinedInput-root': {
                minHeight: '36px',
                borderRadius: '3px',
            },
        },
        '& $locationFieldText , $addressFieldText': {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '14px',
        },
        '& $addressFieldLabelZipValid': {
            '&::after': {
                lineHeight: '12px',
                width: '12px',
                height: '12px',
                bottom: '0px',
                top: '1px',
            },
        },
        '& $submitButton': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 20px',
            minHeight: '36px',
            borderRadius: '3px',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '0.875rem',
            lineHeight: '20px',
            letterSpacing: '0.04em',
            width: '100%',
            textTransform: 'capitalize',
        },
        '& $buttonContainer': {
            width: '100%',
        },
        '& $linkWrapper': {
            textUnderlineOffset: '2px',
            '& *': {
                fontWeight: '400',
                fontSize: '0.75rem',
                lineHeight: '14px',
                textAlign: 'center',
            },
        },
        '& $bottomContainer': {
            marginTop: '24px',
        },
        '& $locationSelect': {
            marginTop: '2px',
        },
        '& $inputFieldContainer': {
            margin: '2px 0px 8px',
        },
        '&  $inputFieldInvalidZip': {
            '&::before': {
                bottom: '-16px',
            },
        },
        '& $headerTextFieldWrapper': {
            margin: '0 auto',
        },
        '& $internationalLinkContainer': {
            margin: '16px 0px 28px',
        },
        '& $closeIconWrapper': {
            top: '12px',
            right: '12px',
            '& svg': {
                fontSize: '0.75rem',
                fill: theme.palette.common?.black || '#000',
                display: 'flex',
            },
        },
    },
}));

const GuidedNavModalView = ({
    guidedNavFormData,
    isOpen,
    handleAddressChange,
    currentAddress,
    selectedLocationType,
    handleLocationFormChange,
    getAddressInformation,
    loadGoogleMaps,
    addressFieldOnFocus,
    handleButtonSubmit,
    closeModal,
    zipValid,
    onSubmitCheck,
    trackEvent,
    zipDerivedFromGoogleMaps,
    qasSuggestions,
    showQasModel,
    hideQasModel,
    handleKeyDown,
}) => {
    const styles = {
        backgroundColor: guidedNavFormData?.background_color_modal,
        formColor: guidedNavFormData?.form_color_modal,
        formBackgroundColor: guidedNavFormData?.form_background_color_modal,
        addressFieldColor: guidedNavFormData?.address_field_color_modal,
        locationTypeColor: guidedNavFormData?.location_type_color_modal,
        submitButtonColor: guidedNavFormData?.submit_button_color_modal,
        submitButtonCopyColor: guidedNavFormData?.submit_button_copy_color_modal,
        bottomLinkColor: guidedNavFormData?.bottom_link_color_modal,
    };

    const abNewGuidedNavFacelift = useSelector(getActiveABTest('new_guided_nav_facelift')) === 'variant';
    const  isUpdatedGnav = useSelector(getFeatureFlag('is-updated-gnav-presentation')) && abNewGuidedNavFacelift;

    const classes = useStyles({ styles });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setGuidedNavModalHasBeenOpenedInSession(true));
        if (isOpen) {
            trackEvent({
                eventCategory: 'Guided Navigation',
                eventAction: 'Shown',
                eventLabel: 'modal',
            });
        }
    }, []);

    const zipCodeVaildCss = zipValid === true && (currentAddress.length >= 5 || zipDerivedFromGoogleMaps !== '')
        ?  classes.addressFieldLabelZipValid : '';

    return (
        <>
            <Dialog
                id="guided-nav-modal"
                open={isOpen}
                className={`${isUpdatedGnav  ? classes.newGnavDialogStyle : ''} ${classes.outerDialog}`}
                classes={{
                    paper: classes.paper,
                }}
                data-testid="guided-nav"
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Grid container className={classes.headerContainer}>
                        <Grid item container className={classes.headerTextContainer}>
                            <Grid item className={classes.headerTextFieldWrapper}>
                                <Typography className={classes.headerText} data-testid="gnav-modal-form-label">
                                    {guidedNavFormData?.form_label_modal || "Filter Gifts In Your Recipient's Location"}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                className={classes.closeIconWrapper}
                                onClick={closeModal}
                                id="guided-nav-modal-close-button"
                                data-testid="guided-nav-modal-close-button"
                            >
                                {isUpdatedGnav ? <CloseIcon />
                                    : <HighlightOffIcon className={classes.closeIcon} /> }
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column" className={classes.dialogContentContainer}>
                        <Grid item container direction="column" className={classes.textFieldContainer}>
                            <Grid
                                container
                                item
                                className={
                                    classes.typographyContainer
                                }
                            >
                                <Typography
                                    className={`${classes.addressFieldText} ${isUpdatedGnav ? zipCodeVaildCss : ''}`}
                                    data-testid="gnav-modal-address-field-label"
                                >
                                    {guidedNavFormData?.address_field_label_modal || 'Where is it going?'}
                                </Typography>
                                {!isUpdatedGnav && (
                                    <Typography className={`${classes.addressRequiredText} ${zipCodeVaildCss}`}>
                                        (required)
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item className={classes.inputFieldContainer}>
                                <TextField
                                    placeholder={isUpdatedGnav ? '' : 'Enter a location'}
                                    className={
                                        (zipValid === false && currentAddress.length === 5) || onSubmitCheck === true
                                            ? `${classes.inputField} ${classes.inputFieldInvalidZip}`
                                            : classes.inputField
                                    }
                                    onChange={handleAddressChange}
                                    onKeyDown={handleKeyDown}
                                    value={currentAddress}
                                    label={isUpdatedGnav ? '' : (guidedNavFormData?.address_field_prompt_label_modal || 'Enter a zip code, delivery address, or city')}
                                    variant="outlined"
                                    id="guided-nav-modal-input-field"
                                    onFocus={() => { addressFieldOnFocus('modal'); }}
                                    InputProps={{
                                        disableUnderline: isUpdatedGnav,
                                        inputProps: {
                                            'data-element': 'guided_nav_field',
                                        },
                                    }}
                                />
                                {currentAddress?.length > 0 && currentAddress && qasSuggestions && showQasModel && <QasSuggestionList handleClickAway={hideQasModel} showModel={showQasModel} handlePickAnAddress={getAddressInformation} suggestions={qasSuggestions} />}
                            </Grid>
                            <Grid container item className={classes.typographyContainer}>
                                <Typography className={classes.locationFieldText}>
                                    {guidedNavFormData?.label_type_color_modal || 'Type of Location?'}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.locationSelect}>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <Select
                                        id="guided-nav-modal-location-field"
                                        inputProps={{
                                            'data-testid': 'gnav-modal-location-dropdown',
                                            classes: {
                                                root: classes.selectField,
                                                icon: classes.icon,
                                            },
                                        }}
                                        value={selectedLocationType}
                                        onChange={handleLocationFormChange}
                                    >
                                        {locations.map((location) => (
                                            <MenuItem key={location} value={location} id={location}>{location}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className={classes.bottomContainer}>
                            <Grid item className={classes.buttonContainer}>
                                <Button
                                    className={classes.submitButton}
                                    onClick={() => handleButtonSubmit(true)}
                                    id="guided-nav-modal-submit-button"
                                    data-testid="guided-nav-modal-submit-button"
                                >
                                    {guidedNavFormData?.submit_button_label_modal || 'Find Gifts Now'}
                                </Button>
                            </Grid>
                            <Grid item className={classes.internationalLinkContainer} data-testid="gnav-modal-bottom-link">
                                <LinkOrATag href={guidedNavFormData?.bottom_link_href_modal || '/international-flower-delivery'} className={classes.linkWrapper}>
                                    <Typography>
                                        {guidedNavFormData?.bottom_link_label_modal || 'Sending outside the US or Canada?'}
                                    </Typography>
                                </LinkOrATag>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {loadGoogleMaps && (
                <GoogleAddressSearch
                    // DO NOT CHANGE ID NAME
                    searchID="guided-nav-modal-input-field"
                    getAddressInformation={getAddressInformation}
                    currentAddress={currentAddress}
                />
            )}
        </>
    );
};

GuidedNavModalView.propTypes = {
    guidedNavFormData: object.isRequired,
    currentAddress: string.isRequired,
    isOpen: bool.isRequired,
    handleAddressChange: func.isRequired,
    selectedLocationType: string.isRequired,
    handleLocationFormChange: func.isRequired,
    getAddressInformation: func.isRequired,
    loadGoogleMaps: bool.isRequired,
    addressFieldOnFocus: func.isRequired,
    handleButtonSubmit: func.isRequired,
    onSubmitCheck: bool.isRequired,
    zipValid: bool.isRequired,
    closeModal: func.isRequired,
    trackEvent: func.isRequired,
    zipDerivedFromGoogleMaps: string.isRequired,
    qasSuggestions: object,
    showQasModel: bool,
    hideQasModel: func,
    handleKeyDown: func.isRequired,
};

GuidedNavModalView.defaultProps = {
    qasSuggestions: { },
    showQasModel: false,
    hideQasModel: () => { },
};

export default GuidedNavModalView;
