/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    string, func, object, bool, array, shape, oneOfType,
} from 'prop-types';
import qs from 'qs';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import {
    getValidatedZipcode,
    getLocationType,
    getProductFilterOccasion,
    getIsProductFilterZipcodeValid,
    getUserSubmittedProductFilterZipcode,
    getSavedAddress,
    getIsBot,
    getGuidedNavModalState,
    getGuidedNavLockedProductClicked,
} from '../../../../../../../../state/ducks/App/App-Selectors';
import {
    validateProductFilterZipcode,
    setLocationType,
    // clearLocationType,
    clearValidatedZipcode,
    resetProductFilterZipcodeValidity,
    setUserSubmittedProductFilterZipcode,
    clearUserSubmittedProductFilterZipcode,
    setUserSelectedAddress,
    clearUserSelectedAddress,
    setGuidedNavProductUnlock,
    setAutoFillAddress,
} from '../../../../../../../../state/ducks/App/App-Actions';
import QasClient from '../../../../../../../../apis/qas-address-search-apis/qas-address-search-apis';
import { trackEvent as trackEventAction } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import GuidedNavUncollapsedView from './GuidedNavUncollapsedView';
import GuidedNavCollapsedView from './GuidedNavCollapsedView';
import GuidedNavModalView from './GuidedNavModalView';
import { getFeatureFlags } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTest, getActiveABTests } from '../../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const GOOGLE_KEY_PWA = mbpUtil.getEnv('APP_GOOGLE_KEY_PWA') || '';
// This unconnected component exported here for testing purposes.
// The connected version is exported by default below.

const isCandaZip = (zipcode) => new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/).test(zipcode);

class GuidedNav extends Component {
    constructor(props) {
        super(props);
        const { userSubmittedZip } = this.props;
        this.state = {
            zipDerivedFromGoogleMaps: '',
            loadGoogleMaps: false,
            currentAddress: userSubmittedZip,
            addressFieldFocus: false,
            addressFieldClicked: false,
            hasError: false,
            onSubmitCheck: false,
            googleMapsAddress: {},
            modalOpen: true,
            loadGoogleMapsModal: false,
            guidedNavModalHasBeenActivated: false,
            qasSuggestions: {},
            showQasModel: false,
            qasFailed: false,
            collapsedGnav: false,
        };
        this.addressFieldRef = React.createRef();
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidMount() {
        // dispatches redux action to set the starting location type which varies by cateogry
        const {
            guidedNavFormData,
            setLocation,
            guidedNavLocationOverride,
            trackEvent,
            guidedNavHasPreviouslyBeenMounted,
            userSubmittedZip,
            featureFlags,
            isBot,
            abTestHideGuidedNavModal,
        } = this.props;
        if (guidedNavFormData) {
            trackEvent({
                eventCategory: 'Guided Navigation',
                eventAction: 'Shown',
                eventLabel: 'widget',
                nonInteraction: '1',
            });
        }
        if (guidedNavLocationOverride?.[0]) {
            setLocation(guidedNavLocationOverride[0]);
        }

        // add script for google places auto complete
        const googleSrc = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY_PWA}&libraries=places`;
        // if doesnt exsist add it / if it does skip
        if (typeof document !== 'undefined' && document.querySelectorAll(`script[src="${googleSrc}"]`).length === 0) {
            const script = document.createElement('script');

            script.src = googleSrc;
            script.async = true;

            document.head.appendChild(script);
        }

        const enabledGuidedNavModal = !isBot
        && featureFlags['is-guided-navigation-modal-enabled']
        && !userSubmittedZip.length
        && !guidedNavHasPreviouslyBeenMounted
        && typeof window !== 'undefined';

        if (enabledGuidedNavModal && abTestHideGuidedNavModal) {
            // test segment to hide modal, dont add the listener
        }  else if (enabledGuidedNavModal) {
            // standard workflow, only trigger on scroll
            window.addEventListener('scroll', this.guidedNavigationModalActivate);
        }
    }

    guidedNavigationModalActivate = () => {
        if (typeof window === 'undefined') {
            return;
        }

        if (window?.location?.hash === '#disclaimer') {
            window.addEventListener('scroll', this.guidedNavModalActivateWhenDisclaimer);
        } else {
            this.setState({
                guidedNavModalHasBeenActivated: true,
            });
        }

        window.removeEventListener('scroll', this.guidedNavigationModalActivate);
    }

    guidedNavModalActivateWhenDisclaimer = () => {
        if (typeof window === 'undefined') {
            return;
        }

        const { guidedNavHasPreviouslyBeenMounted } = this.props;
        const disclaimerEl = typeof document !== 'undefined' ? document.getElementById('disclaimer') : null;
        const scrollY = window?.scrollY;

        if (disclaimerEl?.offsetTop > (scrollY + 100)) {
            this.setState({
                guidedNavModalHasBeenActivated: true,
            });
        }

        if (guidedNavHasPreviouslyBeenMounted) {
            window.removeEventListener('scroll', this.guidedNavModalActivateWhenDisclaimer);
        }
    };

    componentDidUpdate(prevProps) {
        const { zipValid } = this.props;
        if (
            (zipValid === false || zipValid === true)
            && zipValid !== prevProps.zipValid
        ) {
            this.addressFieldRef?.current?.blur();
        }
    }

    componentDidCatch(jsError, reactInfo) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: `Unhandled Error in GuidedNav \n jsError = ${jsError} \n reactInfo = ${reactInfo}`,
        });
    }

    addressFieldOnFocus = (source) => {
        const { qasFailed } = this.state;
        const { featureFlags } = this.props;
        if (source === 'modal') {
            this.setState({
                loadGoogleMapsModal: featureFlags['is-qas-guided-nav-enabled'] ? qasFailed : true,
                showQasModel: true,
            });
        } else {
            this.setState({
                addressFieldFocus: true,
                loadGoogleMaps: featureFlags['is-qas-guided-nav-enabled'] ? qasFailed : true,
                showQasModel: true,
            });
        }
    };

    addressFieldOnClick = () => {
        this.setState({
            addressFieldClicked: true,
        });
    };

    addressFieldOnBlur = () => {
        this.setState({
            addressFieldFocus: false,
            addressFieldClicked: false,
        });
    };

    handleKeyDown = () => {
        this.setState({
            showQasModel: true,
        });
    }

    handleLocationFormChange = (e) => {
        // form state is only in redux
        const { setLocation } = this.props;
        setLocation(e.target.value);
    };

    findPostalCode = (arr) => {
        const postalObj = arr.find((obj) => Object.prototype.hasOwnProperty.call(obj, 'postalCode'));
        if (postalObj?.postalCode) {
            const postalCode = postalObj?.postalCode?.split?.('-');
            return postalCode?.[0] || '';
        }
        return '';
    }

    getAddressInformation = async (autocomplete) => {
        const { featureFlags } = this.props;
        const { qasFailed } = this.state;
        if (featureFlags['is-qas-guided-nav-enabled'] && !qasFailed) {
            const searchTimeout = featureFlags['qas-address-search-timeout-value'] || 5;
            const timeoutInSecs = searchTimeout * 1000;
            const formatParams = qs.parse(autocomplete.format.substring(autocomplete.format.indexOf('?') + 1));
            const response = await QasClient.qasGet({}, 'format', formatParams, timeoutInSecs, true);
            const error = {};
            try {
                const { data: { Message } } = response;
                if (Message) error.errorMessage = Message;
            } catch (err) {
                console.error(err);
            }
            const getZip = this.findPostalCode(response?.data?.address);
            if (!getZip) return;
            const reduxAddress = {
                addressComponents: response?.data,
                addressSource: 'QAS',
            };
            this.setState(
                {
                    zipDerivedFromGoogleMaps: getZip || '', // prevents app from crashing if there is no zipcode provided
                    googleMapsAddress: reduxAddress,
                    currentAddress: autocomplete?.suggestion,
                    onSubmitCheck: false,
                    showQasModel: false,
                },
                this.validateGoogleAddressZip,
            );
        } else {
        // called when a user selects an address from gogle maps
            const addressObj = autocomplete.getPlace();
            // filter through data and find the object with zip code
            const getZip = addressObj.address_components?.filter((d) => (d.types[0] === 'postal_code'));
            if (!getZip) return;
            const reduxAddress = {
                addressComponents: addressObj.address_components,
                addressSource: 'google',
            };

            this.setState(
                {
                    zipDerivedFromGoogleMaps: getZip.length > 0 ? getZip[0].long_name : '', // prevents app from crashing if there is no zipcode provided
                    googleMapsAddress: reduxAddress,
                    currentAddress: addressObj.formatted_address,
                    onSubmitCheck: false,
                },
                this.validateGoogleAddressZip,
            );
        }
    };

    validateGoogleAddressZip = () => {
        // function is used to validate a zipcode when a user
        // selects an address with google maps.
        const { zipDerivedFromGoogleMaps } = this.state;
        const { valZip } = this.props;
        valZip(zipDerivedFromGoogleMaps);
    };

    hideQasModel = (e) => {
        if (e.target?.getAttribute?.('data-element') !== 'guided_nav_field') {
            this.setState({
                showQasModel: false,
            });
        }
    }

    validateUserSubmittedZipcode = async (isModel = false) => {
        // this function determines when to validate a zipcode that the user enters
        // if they do not use the google maps api to select a street address.
        const { currentAddress } = this.state;
        const {
            valZip,
            clearValidatedZip,
            zipValid,
            resetZipValidity,
            validatedZip,
            featureFlags,
        } = this.props;
        let zipCode = currentAddress;
        // we used to remove the space to string at end
        const updatedZipcode = zipCode.trimEnd();
        let country = 'USA';
        if ((updatedZipcode.length === 6 || updatedZipcode.length === 7) && isCandaZip(updatedZipcode)) {
            // if user enters 6 or 7 chars and is canada zipcode
            const zipSplit = updatedZipcode.replace(/\s/gi, '').toUpperCase().split('');
            zipSplit.splice(3, 0, ' ');
            const canadaZipCode = zipSplit.join('');
            zipCode = canadaZipCode;
            valZip(canadaZipCode);
            country = 'CAN';
        } else if (updatedZipcode.length === 5 && /^\d+$/.test(updatedZipcode) === true) {
            // if user enters five chars and those chars are all numbers
            valZip(updatedZipcode);
        } else if (updatedZipcode.length < 5 && validatedZip !== '') {
            // if there are fewer than 5 chars and there exists a zip in redux clear it
            // we do this so that if someone enters a zipcode, then deletes it the filtering goes away.
            clearValidatedZip();
        }
        if (updatedZipcode.length !== 5 && zipValid !== null) {
            // reset the zip is valid flag in redux if zip is not characters.
            resetZipValidity();
        }
        if (zipCode.length > 0 && featureFlags['is-qas-guided-nav-enabled'] && !this.state.qasFailed && zipCode) {
            const searchTimeout = featureFlags['qas-address-search-timeout-value'] || 5;
            const timeoutInSecs = searchTimeout * 1000;
            let zipResult = {};
            if (country === 'USA' && updatedZipcode.length === 5 && /^\d+$/.test(updatedZipcode) === true) {
                const zipSpecific = await QasClient.qasGet({}, 'search', { query: `${zipCode}-0000`, country, take: 1 }, timeoutInSecs, true);
                zipResult = zipSpecific?.data?.results?.[0] || {};
                zipResult.suggestion = zipResult?.suggestion?.split?.(', ');
                zipResult.suggestion = zipResult?.suggestion?.length > 1 ? zipResult?.suggestion?.[1] : zipResult?.suggestion?.[0];
            }
            QasClient.qasGet({}, 'search', { query: zipCode, country, take: 5 - (zipResult?.suggestion ? 1 : 0) }, timeoutInSecs, true).then((res) => {
                if (res?.data?.results) {
                    this.setState({
                        qasSuggestions: {
                            count: res?.data?.count || 0,
                            results: [zipResult, ...res?.data?.results],
                        } || {},
                    });
                }
            }).catch(() => {
                if (isModel) {
                    this.setState({ qasFailed: true, loadGoogleMapsModal: true });
                } else {
                    this.setState({ qasFailed: true, loadGoogleMaps: true });
                }
            });
        } else {
            this.setState({ qasSuggestions: {} });
        }

        this.setState({ currentAddress: zipCode });
    };

    handleAddressChange = (e, isModel) => {
        this.setState(
            {
                currentAddress: e.target.value.trimStart(),
                zipDerivedFromGoogleMaps: '',
                onSubmitCheck: false,
                googleMapsAddress: {},
            },
            () => { this.validateUserSubmittedZipcode(isModel); },
        );
    };

    handleStartOverClick = () => {
        // remove saved zip and location for redux store
        const {
            clearValidatedZip,
            setLocation,
            resetZipValidity,
            guidedNavFormData,
            guidedNavLocationOverride,
            clearFilterZip,
            clearAddress,
            trackEvent,
            clearAutoFillAddress,
        } = this.props;
        this.setState({ collapsedGnav: false });
        clearValidatedZip();
        setTimeout(() => {
            clearFilterZip();
            clearAddress();
            clearAutoFillAddress();
        }, 100);
        resetZipValidity();
        setLocation((guidedNavLocationOverride && guidedNavLocationOverride[0]) ? guidedNavLocationOverride[0] : guidedNavFormData?.default_location_type);
        this.resetCurrentAddress();
        trackEvent({
            eventCategory: 'Guided Navigation',
            eventAction: 'Clicked Start Over',
            eventLabel: 'widget',
        });
    };

    closeModal = () => {
        const { abTestIsGuidedNavModalHardLock, setGuidedNavProductUnlocked, abTestIsGuidedNavModalSoftLock } = this.props;

        // dont allow user to close, show error
        if (abTestIsGuidedNavModalHardLock) {
            this.setState({
                onSubmitCheck: true,
            });
            return;
        }
        if (abTestIsGuidedNavModalSoftLock) {
            setGuidedNavProductUnlocked(true);
        }

        this.setState({
            modalOpen: false,
        });
    }

    renderCollapsedComponentCheck = () => {
        // this function is responsible for determining whether the collapsed component view will render
        // it is possible someone used another component to set the product filter zipcode
        // which means we should render the component collapsed.
        const { userSubmittedZip, occasion } = this.props;
        if (userSubmittedZip !== '' && occasion?.name !== '') {
            return true;
        }
        if (userSubmittedZip !== '') {
            return true;
        }
        return false;
    };

    handleButtonSubmit = (isModalClick = false) => {
        const eLabel = isModalClick ? 'modal' : 'widget';
        const {
            currentAddress, zipDerivedFromGoogleMaps, googleMapsAddress,
        } = this.state;
        const {
            zipValid, setFilterZip, saveAddress, trackEvent, selectedLocationType,
        } = this.props;
        // zipValid will be set to true if user enters a valid zip, or user selects google address
        if (zipValid) {
            // if zip is valid and length is 5 for US and 6 for CA pass user entered zip to setFilterZip dispatch
            // we check for length >= 5 here
            if (zipDerivedFromGoogleMaps === '' && currentAddress.length >= 5) {
                this.setState({ collapsedGnav: true });
                setTimeout(() => {
                    setFilterZip(currentAddress.trimEnd());
                }, 100);
                trackEvent({
                    eventCategory: 'Guided Navigation',
                    eventAction: 'Form successfully submitted',
                    eventLabel: `${eLabel}|${currentAddress}|${selectedLocationType}`,
                });
            }
            // if zip is valid and google zip is NOT empty user has selected an address from google dropdown
            // pass the google zip to setFilterZip dispatch
            // pass google map address to saveAddress dispatch
            if (zipDerivedFromGoogleMaps !== '') {
                this.setState({ collapsedGnav: true });
                setTimeout(() => {
                    setFilterZip(zipDerivedFromGoogleMaps.trimEnd());
                    saveAddress(googleMapsAddress);
                }, 100);
                trackEvent({
                    eventCategory: 'Guided Navigation',
                    eventAction: 'Form successfully submitted',
                    eventLabel: `${eLabel}|${zipDerivedFromGoogleMaps}|${selectedLocationType}`,
                });
            }
            this.setState({
                modalOpen: false,
            });
        }
        // if zip is not true show error UI
        // we check if zip is valid and the user entered zip is not length 5 to prevent the case when a user types 010101 fast

        if (zipValid !== true || (zipValid === true && currentAddress.length !== 5)) {
            this.setState({
                onSubmitCheck: true,
            });
        }
    };

    resetCurrentAddress = () => {
        this.setState({
            zipDerivedFromGoogleMaps: '',
            loadGoogleMaps: false,
            currentAddress: '',
            addressFieldFocus: false,
            addressFieldClicked: false,
            hasError: false,
            onSubmitCheck: false,
            googleMapsAddress: {},
            qasSuggestions: {},
            showQasModel: false,
        });
    }

    render() {
        const {
            guidedNavFormData,
            userSubmittedZip,
            selectedLocationType,
            zipValid,
            trackEvent,
            guidedNavLockedProductClicked,
            activeABTests,
        } = this.props;
        const {
            guidedNavModalHasBeenActivated,
            loadGoogleMaps,
            showQasModel,
            addressFieldFocus,
            addressFieldClicked,
            hasError,
            currentAddress,
            zipDerivedFromGoogleMaps,
            onSubmitCheck,
            modalOpen,
            loadGoogleMapsModal,
            collapsedGnav,
        } = this.state;

        if (hasError) {
            return null;
        }

        const guidedNavModalIsOpen = guidedNavModalHasBeenActivated && modalOpen;

        if (this.renderCollapsedComponentCheck() || collapsedGnav) {
            return  (
                <GuidedNavCollapsedView
                    reduxZip={userSubmittedZip}
                    handleStartOverClick={this.handleStartOverClick}
                    guidedNavFormData={guidedNavFormData}
                />
            );
        }

        return (
            <>
                <GuidedNavUncollapsedView
                    guidedNavFormData={guidedNavFormData}
                    addressFieldOnFocus={this.addressFieldOnFocus}
                    addressFieldOnBlur={this.addressFieldOnBlur}
                    handleAddressChange={(e) => this.handleAddressChange(e)}
                    handleLocationFormChange={this.handleLocationFormChange}
                    handleButtonSubmit={this.handleButtonSubmit}
                    getAddressInformation={this.getAddressInformation}
                    loadGoogleMaps={loadGoogleMaps}
                    addressFieldFocus={addressFieldFocus}
                    addressFieldClicked={addressFieldClicked}
                    addressFieldOnClick={this.addressFieldOnClick}
                    selectedLocationType={selectedLocationType}
                    reduxZip={userSubmittedZip}
                    zipValid={zipValid}
                    currentAddress={currentAddress}
                    zipDerivedFromGoogleMaps={zipDerivedFromGoogleMaps}
                    innerRef={this.addressFieldRef}
                    onSubmitCheck={onSubmitCheck  || (guidedNavLockedProductClicked)}
                    guidedNavModalIsOpen={guidedNavModalIsOpen}
                    showQasModel={showQasModel && !(guidedNavModalIsOpen && (activeABTests.loaded || activeABTests.timeout))}
                    qasSuggestions={this.state.qasSuggestions}
                    hideQasModel={this.hideQasModel}
                    handleKeyDown={this.handleKeyDown}
                />
                {guidedNavModalIsOpen && (activeABTests.loaded || activeABTests.timeout) && (
                    <GuidedNavModalView
                        guidedNavFormData={guidedNavFormData}
                        isOpen={modalOpen}
                        handleAddressChange={(e) => this.handleAddressChange(e, true)}
                        selectedLocationType={selectedLocationType}
                        handleLocationFormChange={this.handleLocationFormChange}
                        currentAddress={currentAddress}
                        getAddressInformation={this.getAddressInformation}
                        loadGoogleMaps={loadGoogleMapsModal}
                        addressFieldOnFocus={this.addressFieldOnFocus}
                        handleButtonSubmit={this.handleButtonSubmit}
                        closeModal={this.closeModal}
                        zipValid={zipValid}
                        onSubmitCheck={onSubmitCheck  || (guidedNavLockedProductClicked)}
                        trackEvent={trackEvent}
                        zipDerivedFromGoogleMaps={zipDerivedFromGoogleMaps}
                        qasSuggestions={this.state.qasSuggestions}
                        showQasModel={showQasModel}
                        hideQasModel={this.hideQasModel}
                        handleKeyDown={this.handleKeyDown}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    validatedZip: getValidatedZipcode(state),
    selectedLocationType: getLocationType(state),
    occasion: getProductFilterOccasion(state),
    zipValid: getIsProductFilterZipcodeValid(state),
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
    savedAddress: getSavedAddress(state),
    guidedNavHasPreviouslyBeenMounted: getGuidedNavModalState(state),
    isBot: getIsBot(state),
    featureFlags: getFeatureFlags(state),
    guidedNavLockedProductClicked: getGuidedNavLockedProductClicked(state),
    abTestHideGuidedNavModal: getActiveABTest('hideGuidedNavModal')(state),
    abTestIsGuidedNavModalHardLock: getActiveABTest('guidedNavModalHardLock')(state),
    abTestIsGuidedNavModalSoftLock: getActiveABTest('guidedNavModalSoftLock')(state),
    activeABTests: getActiveABTests(state),

});

const mapDispatchToProps = (dispatch) => ({
    valZip: bindActionCreators(validateProductFilterZipcode, dispatch),
    trackEvent: bindActionCreators(trackEventAction, dispatch),
    clearValidatedZip: bindActionCreators(clearValidatedZipcode, dispatch),
    setLocation: bindActionCreators(setLocationType, dispatch),
    setGuidedNavProductUnlocked: bindActionCreators(setGuidedNavProductUnlock, dispatch),
    setFilterZip: bindActionCreators(
        setUserSubmittedProductFilterZipcode,
        dispatch,
    ),
    clearFilterZip: bindActionCreators(
        clearUserSubmittedProductFilterZipcode,
        dispatch,
    ),
    resetZipValidity: bindActionCreators(
        resetProductFilterZipcodeValidity,
        dispatch,
    ),
    saveAddress: bindActionCreators(setUserSelectedAddress, dispatch),
    clearAddress: bindActionCreators(clearUserSelectedAddress, dispatch),
    clearAutoFillAddress: bindActionCreators(setAutoFillAddress, dispatch),

});

GuidedNav.propTypes = {
    // map state to props
    featureFlags: object.isRequired,
    guidedNavLockedProductClicked: bool,
    abTestIsGuidedNavModalHardLock: oneOfType([bool, string]),
    abTestHideGuidedNavModal: oneOfType([bool, string]),
    isBot: bool.isRequired,
    guidedNavHasPreviouslyBeenMounted: bool.isRequired,
    selectedLocationType: string.isRequired,
    zipValid: bool,
    validatedZip: string.isRequired,
    userSubmittedZip: string.isRequired,
    occasion: shape({
        name: string.isRequired,
    }).isRequired,

    // map dispatch to props
    valZip: func.isRequired,
    trackEvent: func,
    clearValidatedZip: func.isRequired,
    setLocation: func.isRequired,
    clearFilterZip: func.isRequired,
    resetZipValidity: func.isRequired,
    saveAddress: func.isRequired,
    clearAddress: func.isRequired,
    clearAutoFillAddress: func.isRequired,
    setFilterZip: func.isRequired,

    // props
    guidedNavFormData: object.isRequired,
    guidedNavLocationOverride: array.isRequired,
    abTestIsGuidedNavModalSoftLock: bool,
    setGuidedNavProductUnlocked: func.isRequired,
    activeABTests: object.isRequired,

};

GuidedNav.defaultProps = {
    guidedNavLockedProductClicked: false,
    abTestIsGuidedNavModalHardLock: false,
    abTestIsGuidedNavModalSoftLock: false,
    abTestHideGuidedNavModal: false,
    zipValid: null,
    trackEvent: () => {},
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GuidedNav);
